import addressLookupClient from 'apiHelpers/addressLookupClient';
import { AxiosError } from 'axios';
import { isAxiosError } from 'helpers/axiosResponseHelpers';
import { AddressInfo } from 'state/formData/customerDetails';
import { FullAddress } from './address';

const NO_MATCH_ERROR_CODE = 'EVO_BUS_P114';
const SINGLE_ADDRESS_ERROR_CODE = 'EVO_BUS_P119';

const addressSearch = async (postcode: string): Promise<AddressInfo[] | FullAddress> => {
  try {
    return await addressLookupClient.addressSearch(postcode.trim());
  } catch (err) {
    const error: AxiosError = err as AxiosError;
    if (isAxiosError(error) && error.response?.status === 400) {
      const errorCode = error.response.data?.Code;
      if (errorCode === NO_MATCH_ERROR_CODE) {
        error.response.status = 404;
      }
      // If there is one address, the API errors with an error message indicating to call the single address endpoint
      if (errorCode === SINGLE_ADDRESS_ERROR_CODE) {
        return addressLookupClient.getFullAddress(postcode.trim());
      }
    }
    throw error;
  }
};

export default addressSearch;
