import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import AdditionalFormSectionBanner from 'forms/AdditionalFormSectionBanner';

export const StyledAdditionalFormSectionBanner = styled(AdditionalFormSectionBanner)`
  margin-top: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(1)};  
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(3)};  
  `}
`;
