import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const ImportantInformation = styled(RichTextWithModal)`
  max-width: 544px;
  padding-top: ${spacing(4)};
  ${RichText} {
    h2 {
      ${fonts.headingSmall};
      margin-top: ${spacing(6)};
      margin-bottom: ${spacing(2)};
      ${mediaQuery.tabletPortrait`
        margin-top: ${spacing(4)};
        margin-bottom: ${spacing(3)};
      `}

      ${mediaQuery.tabletLandscape`
        margin-top: ${spacing(6)};
        margin-bottom: ${spacing(3)};
      `}
    }

    p {
      ${fonts.paragraphLarge}
    }
  }
`;
