import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { fonts } from '@rsa-digital/evo-shared-components/theme/Tesco/fonts';
import styled from 'styled-components';
import PanelWithActionLink from 'components/PanelWithActionLink';
import { StyledSpanHeadingMedium } from 'components/PanelWithActionLink/styles';
import CoverStartDateQuestion from './CoverStartDateQuestion';

export const StyledStartCoverDatePanel = styled(PanelWithActionLink)`
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: ${spacing(4)};
  ${StyledSpanHeadingMedium} {
    font-size: ${spacing(3)};
    line-height: ${spacing(4)};
  }
`;

export const StyledSectonHeader = styled.h3`
  ${fonts.paragraphLarge}
  margin-bottom:0;
`;

export const StyledExplanatoryText = styled.p`
  ${fonts.paragraph}
  margin-top:0;
`;

export const StyledCoverStartDateQuestion = styled(CoverStartDateQuestion)`
  margin-top: -${spacing(4)};
`;
