import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import {
  dateToDateValue,
  parseDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import {
  LookupStatus,
  useLookup,
} from '@rsa-digital/evo-shared-components/hooks/useLookup';
import promotionsClient from 'apiHelpers/promotionsClient';
import { useFirstEligibleStartDateFromToday } from 'businessLogic/petAge';
import React, { useEffect, useReducer, useState } from 'react';
import InputWithApplyAndPanel from 'components/InputWithApplyAndPanel';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import NumberOfPetsQuestion from 'forms/AboutYouForm/NumberOfPetsQuestion';
import { ProductId } from 'helpers/businessConstants';
import { areDateValuesEqual, formatLongDateWithOrdinalSuffix } from 'helpers/dateHelpers';
import {
  PageTitle,
  trackAPIError,
  trackFormTextFieldFocus,
  trackLinkClick,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { useOptimizelyGradualRevealExperimentVariation } from 'helpers/optimizelyVariation';
import {
  combinePetNames,
  petNameReplacer,
} from 'helpers/placeholders/dynamicPetNameHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { usePetsDetails } from 'state/formData/petsDetails';
import { initialPromoCodeLookup, usePolicyDetails } from 'state/formData/policyDetails';
import useAboutYourPolicyQuestions from './questions';
import {
  StyledCoverStartDateQuestion,
  StyledExplanatoryText,
  StyledSectonHeader,
  StyledStartCoverDatePanel,
} from './styles';
import { isPromoCodeBlank } from './validation';

type AboutYourPolicyFormProps = {
  formValidation: {
    getError: FieldFunction<FormDetails, string | undefined>;
    getWarning: FieldFunction<FormDetails, string | undefined>;
    showValidation: FieldFunction<FormDetails, void>;
  };
};

const AboutYourPolicyForm: React.FC<AboutYourPolicyFormProps> = ({ formValidation }) => {
  const { getError, getWarning, showValidation } = formValidation;

  const questions = useAboutYourPolicyQuestions();
  const [policyDetails, updatePolicyDetails] = usePolicyDetails();
  const [petsDetails] = usePetsDetails();

  const [minDate, isMinDateInFuture] = useFirstEligibleStartDateFromToday();

  const coverStartDateUtc = new Date(policyDetails.coverStartDate);

  const formattedCoverStartDate = formatLongDateWithOrdinalSuffix(
    coverStartDateUtc,
    false
  );

  const isGradulaRevealExperiment = useOptimizelyGradualRevealExperimentVariation();

  // If the any of the Pets are too young at the current date, we restrict the date picker
  // to ensure they are valid at the cover start date and display a special message.
  const [displayYoungPetMessage, setDisplayYoungPetMessage] = useState(false);

  useEffect(() => {
    setDisplayYoungPetMessage(isMinDateInFuture);
  }, [isMinDateInFuture]);

  const {
    performLookup: performPromoCodeLookup,
    getLookupError: getPromoCodeLookupError,
  } = useLookup(
    policyDetails.promoCode,
    (update) =>
      updatePolicyDetails({
        promoCode: update,
      }),
    promotionsClient.promoCode,
    () => null,
    questions.promoCode.errorMessages,
    trackAPIError
  );
  const { questionText } = questions.coverStartDate;
  const explanatoryText = displayYoungPetMessage
    ? questions.coverStartDate.youngPetExplanatoryText
    : questions.coverStartDate.explanatoryText;

  // ========================================================================================
  // =       Optimizely A/B Experiment - Start Cover Date Panel vs Calendar                 =
  // ========================================================================================

  // Hard coded the variation #1 value instead of getting from ENV variables, for the ticket -  PE-372 - Policy start date vs calendar AB Test - Hardcoding.

  const optimizelyCoverStartDateVariation = 'Variation #1';
  const isOptimizelyNewVariation = optimizelyCoverStartDateVariation === 'Variation #1';

  // We show/hide calendar initially based on the value of coverStartDateVariation:
  // - If not isOptimizelyNewVariation  => We initially SHOW the calendar
  // - If isOptimizelyNewVariation      => We initially HIDE the calendar

  const [showCalendar, toggleCalendar] = useReducer(
    (state) => !state,
    !isOptimizelyNewVariation
  );

  const petNames = petsDetails.map((pet) => pet.petName);
  const combinedPetNames = combinePetNames(petNames ?? []);

  const isStartDateToday = areDateValuesEqual(
    dateToDateValue(new Date()),
    dateToDateValue(parseDate(policyDetails.coverStartDate))
  );

  const startDateText = isStartDateToday
    ? petNameReplacer(
        combinedPetNames,
        questions.start_date_panel.your_cover_will_start_today_label
      )
    : petNameReplacer(
        combinedPetNames,
        questions.start_date_panel.your_cover_will_start_label
      );

  const quote = useCurrentQuote();

  return (
    <>
      {isGradulaRevealExperiment && (
        <NumberOfPetsQuestion
          formValidation={{
            getError,
            getWarning,
            showValidation,
          }}
        />
      )}
      {isOptimizelyNewVariation && (
        <Grid alignLeft>
          <GridItem desktop={6} tabletLandscape={6}>
            <StyledSectonHeader>{questionText}</StyledSectonHeader>
            <StyledExplanatoryText data-cy="explanatory-text">
              {explanatoryText}
            </StyledExplanatoryText>
            <StyledStartCoverDatePanel
              id="cover-start-date"
              summaryText={startDateText}
              heading={formattedCoverStartDate}
              link={{
                onClick: () => {
                  toggleCalendar();
                  trackLinkClick(
                    PageTitle.CoverStartDate,
                    showCalendar
                      ? questions.start_date_panel.hide_calendar_text
                      : questions.start_date_panel.choose_a_different_start_date_text
                  );
                },
                text: showCalendar
                  ? questions.start_date_panel.hide_calendar_text
                  : questions.start_date_panel.choose_a_different_start_date_text,
                screenreaderText: showCalendar
                  ? questions.start_date_panel.hide_calendar_screenreader_text
                  : questions.start_date_panel
                      .choose_a_different_start_date_screenreader_text,
              }}
            />
          </GridItem>
        </Grid>
      )}
      {showCalendar && (
        <StyledCoverStartDateQuestion
          hideQuestionText
          minDate={minDate}
          onChangeCallback={() => setDisplayYoungPetMessage(false)}
        />
      )}
      {ProductId.DIRECT === quote.productId && (
        <InputWithApplyAndPanel
          id="promoCodePanel"
          inputId="promoCode"
          addAriaLabel="Apply promotional code"
          removeAriaLabel="Remove promotional code"
          question={questions.promoCode}
          errorText={getError('promoCode')}
          lookupStatus={policyDetails.promoCode.status}
          text={{
            successPanel: questions.promoCode.successPanelText,
            errorPanel: getPromoCodeLookupError() || '',
          }}
          value={policyDetails.promoCode.lookupKey}
          onChange={(e) =>
            updatePolicyDetails({
              promoCode: {
                ...initialPromoCodeLookup,
                lookupKey: e.target.value.toUpperCase().replaceAll(' ', ''),
              },
            })
          }
          placeholder={questions.promoCode.placeholder}
          onFocus={trackFormTextFieldFocus('promoCode')}
          maxLength={12}
          loading={policyDetails.promoCode.status === LookupStatus.InProgress}
          onKeyDown={(e) => {
            if (
              e.key === 'Enter' &&
              !(
                isPromoCodeBlank(policyDetails) ||
                policyDetails.promoCode.status === LookupStatus.InProgress
              )
            ) {
              e.preventDefault();
              performPromoCodeLookup();
            }
          }}
          onApply={() => {
            performPromoCodeLookup();
            trackTextButtonClick(PageTitle.AboutYouAndYourPet, 'Apply promocode');
          }}
          onRemove={() => {
            updatePolicyDetails({
              promoCode: initialPromoCodeLookup,
            });
            trackTextButtonClick(PageTitle.AboutYouAndYourPet, 'Remove promocode');
          }}
          disableApply={isPromoCodeBlank(policyDetails)}
          applySuccess={policyDetails.promoCode.status === LookupStatus.Success}
        />
      )}
    </>
  );
};

export default AboutYourPolicyForm;
