import useValidationWithWarnings from '@rsa-digital/evo-shared-components/helpers/forms/useValidationWithWarnings';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import FormFooter from 'components/FormFooter';
import ClubcardSection from 'components/QuoteSummary/ClubcardSection';
import AboutYouForm from 'forms/AboutYouForm';
import useAddressRules from 'forms/AboutYouForm/AddressForm/validation';
import useAboutYouRules from 'forms/AboutYouForm/validation';
import AboutYourPetsForm from 'forms/AboutYourPetsForm';
import useAboutYourPetRules from 'forms/AboutYourPetsForm/AboutYourPet/validation';
import AboutYourPolicyForm from 'forms/AboutYourPolicyForm';
import useAboutYourPolicyRules from 'forms/AboutYourPolicyForm/validation';
import AdditionalFormSectionBanner from 'forms/AdditionalFormSectionBanner';
import useClubcardRules from 'forms/ClubcardForm/validation';
import JointPolicyholderForm from 'forms/JointPolicyholderForm';
import useJointPolicyholderRules from 'forms/JointPolicyholderForm/validation';
import KeepingYouInformedForm from 'forms/KeepingYouInformedForm';
import useKeepingYouInformedRules from 'forms/KeepingYouInformedForm/validation';
import SectionHeading from 'forms/SectionHeading';
import { areDateValuesEqual } from 'helpers/dateHelpers';
import { PageTitle, trackFieldError, trackTextButtonClick } from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { isQuoteFromAggs } from 'helpers/productHelpers';
import {
  useValidateAboutYouForm,
  useValidateAboutYourPolicyForm,
} from 'helpers/revealFormValidation';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { useJointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { usePetsDetails } from 'state/formData/petsDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import { initialDateValue } from 'state/formData/shared/dateValue';
import { CsAdditionalFormSectionBanner } from 'types/contentStack';
import { FormDetails } from './types';
import { useDisplayAssumptionWarningsOnRender } from './useDisplayAssumptionWarningsOnRender';

type AboutYouAndYourPetFormData = {
  csPetAboutYouAndYourPet: {
    joint_policyholder_banner: CsAdditionalFormSectionBanner;
    about_you_section_heading: string;
    joint_policyholder_section_heading: string;
    about_your_policy_section_heading: string;
    keeping_you_informed_section_heading: string;
    next_button_text: string;
  };
};

const query = graphql`
  query {
    csPetAboutYouAndYourPet {
      joint_policyholder_banner {
        heading_text
        body_text
        button_text
        button_aria_label
      }
      next_button_text
      about_you_section_heading
      joint_policyholder_section_heading
      about_your_policy_section_heading
      keeping_you_informed_section_heading
    }
  }
`;

type AboutYouAndYourPetFormProps = {
  moveNext: () => void;
};

const AboutYouAndYourPetForm: React.FC<AboutYouAndYourPetFormProps> = ({ moveNext }) => {
  const {
    csPetAboutYouAndYourPet: {
      joint_policyholder_banner,
      about_you_section_heading,
      joint_policyholder_section_heading,
      about_your_policy_section_heading,
      keeping_you_informed_section_heading,
      next_button_text,
    },
  } = useStaticQuery<AboutYouAndYourPetFormData>(query);

  const aboutYourPetSectionId = 'about-your-pet-section';
  const aboutYouSectionId = 'about-you-section';
  const jointPolicyholderSectionId = 'joint-policyholder-section';
  const aboutYourPolicySectionId = 'about-your-policy-section';
  const keepingYouInformedSectionId = 'keeping-you-informed-section';

  const quote = useCurrentQuote();
  const isAggsQuote = isQuoteFromAggs(quote);

  const [petDetails] = usePetsDetails();
  const petRules = useAboutYourPetRules();

  const [customerDetails] = useCustomerDetails();
  const customerRules = useAboutYouRules();
  const addressRules = useAddressRules();

  const [
    jointPolicyholderDetails,
    updateJointPolicyholderDetails,
  ] = useJointPolicyholderDetails();
  const jointPolicyHolderRules = useJointPolicyholderRules();

  const [displayJointPolicyholderForm, setDisplayJointPolicyholderForm] = useState(
    jointPolicyholderDetails.includeJointPolicyholder
  );

  // warning panel is displayed when the customer enters their own details in the joint policyholder form
  const displayJointPolicyholderWarningPanel =
    customerDetails.customerFirstName !== '' &&
    customerDetails.customerFirstName ===
      jointPolicyholderDetails.jointPolicyholderFirstName &&
    customerDetails.customerLastName !== '' &&
    customerDetails.customerLastName ===
      jointPolicyholderDetails.jointPolicyholderLastName &&
    !areDateValuesEqual(customerDetails.customerDob, initialDateValue) &&
    areDateValuesEqual(
      customerDetails.customerDob,
      jointPolicyholderDetails.jointPolicyholderDob
    );

  const [policyDetails] = usePolicyDetails();
  const policyRules = useAboutYourPolicyRules();

  const clubcardRules = useClubcardRules(PageTitle.AboutYouAndYourPet, isAggsQuote);

  const keepingYouInformedRules = useKeepingYouInformedRules();
  const validateAboutYouFormFields = useValidateAboutYouForm();
  const validateAboutYourPolicyFormFields = useValidateAboutYourPolicyForm();

  const formErrorRules = {
    ...petRules.errors,
    ...customerRules.errors,
    ...addressRules,
    ...(jointPolicyholderDetails.includeJointPolicyholder ? jointPolicyHolderRules : {}),
    ...(!isAggsQuote ? keepingYouInformedRules : {}),
    ...clubcardRules,
    ...policyRules,
  };

  const formWarningRules = {
    ...petRules.warnings,
    ...customerRules.warnings,
  };

  const formDetails: FormDetails = {
    petDetails,
    ...customerDetails,
    ...jointPolicyholderDetails,
    ...policyDetails,
  };

  const {
    getError,
    getWarning,
    showValidation,
    resetValidation,
    validateOnSubmit,
  } = useValidationWithWarnings(
    formDetails,
    formErrorRules,
    formWarningRules,
    trackFieldError
  );

  useDisplayAssumptionWarningsOnRender(showValidation);

  const [petsDetails] = usePetsDetails();
  const [allPetsAreEligible, setAllPetsAreEligible] = useState<boolean>(false);
  const [allPetsAreHealthy, setAllPetsAreHealthy] = useState<boolean>(false);

  useEffect(() => {
    setAllPetsAreEligible(!petsDetails.some((pet) => !pet.petIsEligible));
    setAllPetsAreHealthy(!petsDetails.some((pet) => !pet.petInGoodHealth));
  }, [
    petsDetails,
    allPetsAreEligible,
    allPetsAreHealthy,
    customerDetails,
    validateAboutYouFormFields,
    validateAboutYourPolicyFormFields,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      <section id={aboutYourPetSectionId} aria-label="Your pet details">
        <AboutYourPetsForm
          formValidation={{
            getError,
            getWarning,
            showValidation,
            resetValidation,
          }}
          {...{
            allPetsAreEligible,
            allPetsAreHealthy,
          }}
        />
      </section>
      {validateAboutYouFormFields && (
        <>
          <section aria-labelledby={aboutYouSectionId}>
            <SectionHeading heading={about_you_section_heading} id={aboutYouSectionId} />

            <AboutYouForm
              formValidation={{
                getError,
                getWarning,
                showValidation,
              }}
            />
          </section>

          {validateAboutYourPolicyFormFields && (
            <>
              {displayJointPolicyholderForm ? (
                <section aria-labelledby={jointPolicyholderSectionId}>
                  <SectionHeading
                    heading={joint_policyholder_section_heading}
                    id={jointPolicyholderSectionId}
                  />
                  <JointPolicyholderForm
                    removeJointPolicyholderButtonOnClick={() =>
                      setDisplayJointPolicyholderForm(false)
                    }
                    formValidation={{
                      getError,
                      showValidation,
                    }}
                    displayWarningPanel={displayJointPolicyholderWarningPanel}
                  />
                </section>
              ) : (
                <AdditionalFormSectionBanner
                  data-cy="addJointPolicyholderBanner"
                  headingText={joint_policyholder_banner.heading_text}
                  bodyText={joint_policyholder_banner.body_text}
                  buttonText={joint_policyholder_banner.button_text}
                  buttonAriaLabel={joint_policyholder_banner.button_aria_label}
                  buttonId="addJointPolicyholderButton"
                  addFormSectionButtonClick={() => {
                    updateJointPolicyholderDetails({
                      includeJointPolicyholder: true,
                    });
                    setDisplayJointPolicyholderForm(true);
                    trackTextButtonClick(
                      PageTitle.AboutYouAndYourPet,
                      'Add joint policy holder'
                    );
                  }}
                />
              )}
            </>
          )}
          {validateAboutYouFormFields && validateAboutYourPolicyFormFields && (
            <>
              <section aria-labelledby={aboutYourPolicySectionId}>
                <SectionHeading
                  heading={about_your_policy_section_heading}
                  id={aboutYourPolicySectionId}
                />
                <AboutYourPolicyForm
                  formValidation={{
                    getError,
                    getWarning,
                    showValidation,
                  }}
                />
              </section>
              {/* {(customerDetails.clubcardSearch.status !== 'NONE' ||
            clubcardIsStaffFromSearch(customerDetails) ||
            !!customerDetails.clubcardLookup.lookupKey) && (
            <section aria-labelledby={clubcardSectionId} data-cy="clubcardSection">
              <SectionHeading heading={clubcard_section_heading} id={clubcardSectionId} /> */}
              <ClubcardSection
                formValidation={{
                  getError,
                  showValidation,
                }}
                pageTitle={PageTitle.AboutYouAndYourPet}
                isAggsQuote={isAggsQuote}
              />
              {/* </section>
          )} */}
              {!isAggsQuote && (
                <section aria-labelledby={keepingYouInformedSectionId}>
                  <SectionHeading
                    heading={keeping_you_informed_section_heading}
                    id={keepingYouInformedSectionId}
                  />
                  <KeepingYouInformedForm
                    formValidation={{
                      getError,
                      showValidation,
                    }}
                  />
                </section>
              )}
            </>
          )}
        </>
      )}
      <FormFooter
        moveNextButton={{
          text: next_button_text,
          onClick: () =>
            trackTextButtonClick(PageTitle.AboutYouAndYourPet, 'Submit details'),
        }}
        pageTitle={PageTitle.AboutYouAndYourPet}
      />
    </form>
  );
};

export default AboutYouAndYourPetForm;
