import SegmentedSelector from '@rsa-digital/evo-shared-components/components/Form/SegmentedSelector';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { scrollAndFocusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import React from 'react';
import PromotionalBox from 'components/PromotionalBox';
import QuestionField from 'components/QuestionField';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import { addItem } from 'helpers/arrayHelper';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import {
  PageTitle,
  trackRadioButtonClick,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { useTouchAssumption } from 'state/formData/assumptionsAgreement';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { initialPet, usePetsDetails } from 'state/formData/petsDetails';
import useAboutYouQuestions from '../questions';

type NumberOfPetsQuestionProps = {
  formValidation: {
    getError: FieldFunction<FormDetails, string | undefined>;
    getWarning: FieldFunction<FormDetails, string | undefined>;
    showValidation: FieldFunction<FormDetails, void>;
  };
};

const NumberOfPetsQuestion: React.FC<NumberOfPetsQuestionProps> = ({
  formValidation,
}) => {
  const { getError, getWarning, showValidation } = formValidation;
  const questions = useAboutYouQuestions();
  const [customerDetails, updateCustomerDetails] = useCustomerDetails();
  const [petsDetails, updatePetsDetails] = usePetsDetails();
  const touchAssumption = useTouchAssumption();

  const numberOfPetsInQuote = petsDetails.length;

  return (
    <>
      <QuestionField
        question={questions.numberOfPetsInHousehold.question}
        errorText={getError('numberOfPetsInHousehold')}
        warningText={getWarning('numberOfPetsInHousehold')}>
        <SegmentedSelector
          id="numberOfPetsInHousehold"
          value={customerDetails.numberOfPetsInHousehold?.toString()}
          shownOptions={5}
          options={[
            {
              name: '1',
              value: '1',
            },
            {
              name: '2',
              value: '2',
            },
            {
              name: '3',
              value: '3',
            },
            {
              name: '4',
              value: '4',
            },
            {
              name: '5+',
              value: '5',
            },
          ]}
          onChange={(event) => {
            touchAssumption([
              'one_pet_in_household',
              'two_pets_in_household',
              'three_pets_in_household',
            ]);
            updateCustomerDetails({
              numberOfPetsInHousehold: Number(event.target.value),
            });
            trackRadioButtonClick('Number of pets in household', event.target.value);
            showValidation('numberOfPetsInHousehold');
          }}
        />
      </QuestionField>
      {customerDetails.numberOfPetsInHousehold &&
        numberOfPetsInQuote < 3 &&
        customerDetails.numberOfPetsInHousehold > numberOfPetsInQuote && (
          <Grid alignLeft>
            <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
              <PromotionalBox
                pageTitle={PageTitle.AboutYouAndYourPet}
                id="number-of-pets-promotional-box"
                icon={
                  unwrapSingleton(questions.numberOfPetsInHousehold.promotionalBox.icon)
                    ?.icon_code
                }
                heading={questions.numberOfPetsInHousehold.promotionalBox.heading}
                information={questions.numberOfPetsInHousehold.promotionalBox.information}
                button={{
                  buttonText:
                    questions.numberOfPetsInHousehold.promotionalBox.button_text,
                  screenReaderText:
                    questions.numberOfPetsInHousehold.promotionalBox
                      .button_screenreader_text,
                  onClick: () => {
                    updatePetsDetails(addItem(petsDetails, initialPet));
                    /* This use of setTimeout forces scrollAndFocusInput to be called after
                     * updatePetsDetails, so that we attempt to scroll to the newly added pet
                     * form section *after* the section has been rendered in the DOM.
                     *
                     * See https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful for more
                     * details about this implementation.
                     */
                    setTimeout(() =>
                      scrollAndFocusInput(`petDetails[${petsDetails.length}]`)
                    );
                    trackTextButtonClick(
                      PageTitle.AboutYouAndYourPet,
                      'Add a pet - number of pets in household'
                    );
                  },
                }}
              />
            </GridItem>
          </Grid>
        )}
    </>
  );
};

export default NumberOfPetsQuestion;
