import { graphql, useStaticQuery } from 'gatsby';
import { PageTitle } from 'helpers/eventTracking';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion, CsQuestionWithPlaceholder } from 'types/contentStack';
import { QuestionWithOptionalPlaceholder, QuestionWithPlaceholder } from 'types/forms';

type AddressQuestionDetails = {
  postcodeLookup: {
    postcode: QuestionWithOptionalPlaceholder;
    selectAddress: QuestionWithPlaceholder;
  };
  manual: {
    sectionLabel: string;
    flatNameOrNumber: QuestionWithOptionalPlaceholder;
    houseNameOrNumber: QuestionWithOptionalPlaceholder;
    street: QuestionWithOptionalPlaceholder;
    town: QuestionWithOptionalPlaceholder;
    county: QuestionWithOptionalPlaceholder;
    postcode: QuestionWithOptionalPlaceholder;
  };
  actionText: {
    buttonText: string;
    switchToManualBeforeSearch: string;
    switchToManualAfterSearch: string;
    switchToPostcodeLookup: string;
    changeAddress: string;
  };
};

type CsAddressQuestions = {
  csPetAboutYouPostcodeLookup: {
    postcode: CsQuestionWithPlaceholder;
    address_select: CsQuestion;
    button_text: string;
    action_link_text: {
      change_address_link_text: string;
      switch_to_manual_after_search_link_text: string;
      switch_to_manual_before_search_link_text: string;
    };
  };
  csPetAboutYouManualAddress: {
    section_label: string;
    postcode_lookup_link_text: string;
    flat_name_or_number: CsQuestionWithPlaceholder;
    house_name_or_number: CsQuestionWithPlaceholder;
    street: CsQuestionWithPlaceholder;
    town: CsQuestionWithPlaceholder;
    county: CsQuestionWithPlaceholder;
    postcode: CsQuestionWithPlaceholder;
  };
};

const query = graphql`
  query {
    csPetAboutYouPostcodeLookup {
      postcode {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      address_select {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      button_text
      action_link_text {
        change_address_link_text
        switch_to_manual_after_search_link_text
        switch_to_manual_before_search_link_text
      }
    }
    csPetAboutYouManualAddress {
      section_label
      postcode_lookup_link_text
      flat_name_or_number {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      house_name_or_number {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      street {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      town {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      county {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      postcode {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
    }
  }
`;

const useAddressQuestions = (): AddressQuestionDetails => {
  const csQuestions = useStaticQuery<CsAddressQuestions>(query);
  const {
    processQuestionWithOptionalPlaceholder,
    processQuestionWithPlaceholder,
  } = useQuestionProcessor(PageTitle.AboutYouAndYourPet);

  return {
    postcodeLookup: {
      postcode: processQuestionWithOptionalPlaceholder(
        csQuestions.csPetAboutYouPostcodeLookup.postcode
      ),
      selectAddress: processQuestionWithPlaceholder(
        csQuestions.csPetAboutYouPostcodeLookup.address_select
      ),
    },
    manual: {
      sectionLabel: csQuestions.csPetAboutYouManualAddress.section_label,
      flatNameOrNumber: processQuestionWithOptionalPlaceholder(
        csQuestions.csPetAboutYouManualAddress.flat_name_or_number
      ),
      houseNameOrNumber: processQuestionWithOptionalPlaceholder(
        csQuestions.csPetAboutYouManualAddress.house_name_or_number
      ),
      street: processQuestionWithOptionalPlaceholder(
        csQuestions.csPetAboutYouManualAddress.street
      ),
      town: processQuestionWithOptionalPlaceholder(
        csQuestions.csPetAboutYouManualAddress.town
      ),
      county: processQuestionWithOptionalPlaceholder(
        csQuestions.csPetAboutYouManualAddress.county
      ),
      postcode: processQuestionWithOptionalPlaceholder(
        csQuestions.csPetAboutYouManualAddress.postcode
      ),
    },
    actionText: {
      buttonText: csQuestions.csPetAboutYouPostcodeLookup.button_text,
      switchToManualBeforeSearch:
        csQuestions.csPetAboutYouPostcodeLookup.action_link_text
          .switch_to_manual_before_search_link_text,
      switchToManualAfterSearch:
        csQuestions.csPetAboutYouPostcodeLookup.action_link_text
          .switch_to_manual_after_search_link_text,
      switchToPostcodeLookup:
        csQuestions.csPetAboutYouManualAddress.postcode_lookup_link_text,
      changeAddress:
        csQuestions.csPetAboutYouPostcodeLookup.action_link_text.change_address_link_text,
    },
  };
};

export default useAddressQuestions;
