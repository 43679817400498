import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { RadioItem } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import TypeaheadInput from 'components/TypeaheadInput';

export const QuestionFieldWithReducedBottomMargin = styled(QuestionField)<{
  showBreedInfoPanel: boolean;
}>`
  margin-bottom: ${({ showBreedInfoPanel }) =>
    showBreedInfoPanel ? spacing(2) : undefined};
`;

export const RadioInputWithPadding = styled(RadioInput)`
  &&&& {
    ${RadioItem} {
      padding: ${spacing(3)};

      ${mediaQuery.tabletPortrait`
        padding: ${spacing(3)} ${spacing(6)};
      `}
      & svg {
        color: ${colors.core01};
      }
    }
    input:checked + label {
      & svg,
      p {
        color: ${colors.core02};
      }
    }
  }
`;

export const StyledTypeaheadInput = styled(TypeaheadInput)`
  input {
    &::placeholder {
      & {
        font-weight: normal !important;
      }
    }
  }
`;
