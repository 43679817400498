import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import React from 'react';
import BreedPlaybackPanel from 'components/BreedPlaybackPanel';
import CustomModal from 'components/CustomModal';
import QuestionField from 'components/QuestionField';
import useRichTextWithModal from 'components/RichTextWithModal/useRichTextwithModal';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import { DesignConstancts } from 'helpers/businessConstants';
import {
  PageTitle,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackRadioButtonClick,
  trackRichTextLinkClick,
} from 'helpers/eventTracking';
import getPetIdForInput from 'helpers/getPetId';
import { INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE } from 'helpers/inputRegexes';
import {
  dogBreedType_CROSS_BREED,
  dogBreedType_MONGREL,
  dogBreedType_PEDIGREE,
  petType_DOG,
} from 'helpers/referenceDataConstants';
import { Pet, PetWithKey } from 'state/formData/petsDetails';
import { ReferenceDataOption } from 'types/referenceData';
import {
  QuestionFieldWithReducedBottomMargin,
  RadioInputWithPadding,
  StyledTypeaheadInput,
} from './styles';
import {
  useDogBreedNoMatchesText,
  useDogBreedTypeOptions,
} from '../../useDogBreedTypeOptions';
import { useMongrelSizeOptions } from '../../useMongrelSizeOptions';
import useAboutYourPetQuestions from '../questions';

type DogBreedQuestionsProps = {
  petDetails: Pet;
  updatePetDetails: (update: Partial<PetWithKey>) => void;
  index: number;
  formValidation: {
    getError: FieldFunction<FormDetails, string | undefined>;
    showValidation: FieldFunction<FormDetails, void>;
  };
  dogBreedReferenceData: ReferenceDataOption[];
  pageTitle: PageTitle;
};

const DogBreedQuestions: React.FC<DogBreedQuestionsProps> = ({
  petDetails,
  updatePetDetails,
  index,
  formValidation: { getError, showValidation },
  dogBreedReferenceData,
  pageTitle,
}) => {
  const questions = useAboutYourPetQuestions(petDetails);

  const getIdForInput = getPetIdForInput(index);
  const getAnalyticsDescriptionForInput = (input: string): string =>
    `Pet ${index} - ${input}`;

  const dogBreedTypeOptions = useDogBreedTypeOptions();
  const mongrelSizeOptions = useMongrelSizeOptions();

  const dogBreedNoMatchesText = useDogBreedNoMatchesText();

  const showDogQuestions = petDetails.petType === petType_DOG;

  const showPedigreeDogBreedNameQuestion =
    showDogQuestions && petDetails.dogBreedType === dogBreedType_PEDIGREE;
  const showCrossBreedDogBreedNameQuestion =
    showDogQuestions && petDetails.dogBreedType === dogBreedType_CROSS_BREED;
  const showMongrelSizeQuestion =
    showDogQuestions && petDetails.dogBreedType === dogBreedType_MONGREL;

  const dogBreedNameMappedOptions = dogBreedReferenceData.map((option) => ({
    id: option.value,
    label: option.name,
  }));

  const nonMongrelBreedNameQuestion = showPedigreeDogBreedNameQuestion
    ? questions.pedigreeDogBreedName
    : questions.crossBreedDogBreedName;

  const nonMongrelBreedNameAnalyticsDescription = showPedigreeDogBreedNameQuestion
    ? 'Pedigree dog breed name'
    : 'Cross breed dog breed name';

  // Dynamically set petDetails property key to use for dog breed data:
  // - Pedigree => petDetails.dogPedigreeBreedName
  // - Other    => petDetails.dogCrossBreedName
  // Note if Mongrel is selected, this variable will be ignored
  // because nonMongrelBreedNameQuestionField will not be shown.
  const dogBreedNamePropertyKey = showPedigreeDogBreedNameQuestion
    ? 'dogPedigreeBreedName'
    : 'dogCrossBreedName';

  const showBreedInfoPanel =
    (showMongrelSizeQuestion && !!petDetails.mongrelSize) ||
    (!!petDetails.dogBreedType && !!petDetails[dogBreedNamePropertyKey]);

  const menuMaxHeight = DesignConstancts.PET_BREED_DROPDOWN_MENU_MAX_HEIGHT;

  const nonMongrelBreedNameQuestionField = (
    <QuestionFieldWithReducedBottomMargin
      question={nonMongrelBreedNameQuestion}
      errorText={getError('petDetails', [dogBreedNamePropertyKey, index])}
      initiallyShowTooltip={showCrossBreedDogBreedNameQuestion}
      showBreedInfoPanel={showBreedInfoPanel}>
      <StyledTypeaheadInput
        id={getIdForInput(dogBreedNamePropertyKey)}
        value={dogBreedNameMappedOptions.find(
          (option) => option.id === petDetails[dogBreedNamePropertyKey]
        )}
        options={dogBreedNameMappedOptions ?? []}
        placeholder={nonMongrelBreedNameQuestion.placeholder}
        noMatchesText={dogBreedNoMatchesText}
        menuMaxHeight={menuMaxHeight}
        onChange={(option) => {
          updatePetDetails({
            [dogBreedNamePropertyKey]: option?.id,
          });
          trackFormDropdownSelect(
            getAnalyticsDescriptionForInput(nonMongrelBreedNameAnalyticsDescription),
            option?.label ?? 'none'
          );
        }}
        onBlur={() => showValidation('petDetails', [dogBreedNamePropertyKey, index])}
        onFocus={trackFormDropdownFocus(
          getAnalyticsDescriptionForInput(nonMongrelBreedNameAnalyticsDescription),
          pageTitle
        )}
        onKeyPress={(e) => {
          if (!e.key.match(INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE)) {
            e.preventDefault();
          }
        }}
      />
    </QuestionFieldWithReducedBottomMargin>
  );

  const { modalId, setModalId, handleModalButtonClick } = useRichTextWithModal();
  return (
    <>
      <CustomModal
        modalId={modalId}
        onClose={() => {
          setModalId(null);
        }}
        pageTitle={pageTitle}
        trackRichTextLinkClick={trackRichTextLinkClick(pageTitle)}
      />
      <QuestionField
        question={questions.dogBreedType}
        errorText={getError('petDetails', ['dogBreedType', index])}
        onLinkClick={handleModalButtonClick}
        initiallyShowTooltip
        data-cy="dogBreedTypeField">
        <RadioInput
          id={getIdForInput('dogBreedType')}
          value={petDetails.dogBreedType}
          options={dogBreedTypeOptions}
          onChange={(e) => {
            updatePetDetails({
              dogBreedType: e.target.value,
              [dogBreedNamePropertyKey]: '',
            });
            trackRadioButtonClick(
              getAnalyticsDescriptionForInput('Dog breed type'),
              e.target.value
            );
          }}
        />
      </QuestionField>
      {showPedigreeDogBreedNameQuestion && nonMongrelBreedNameQuestionField}
      {showCrossBreedDogBreedNameQuestion && nonMongrelBreedNameQuestionField}
      {showMongrelSizeQuestion && (
        <QuestionFieldWithReducedBottomMargin
          question={questions.mongrelSize}
          errorText={getError('petDetails', ['mongrelSize', index])}
          showBreedInfoPanel={showBreedInfoPanel}>
          <RadioInputWithPadding
            id={getIdForInput('mongrelSize')}
            value={petDetails.mongrelSize}
            options={mongrelSizeOptions}
            onChange={(e) => {
              updatePetDetails({
                mongrelSize: e.target.value,
              });
              trackRadioButtonClick(
                getAnalyticsDescriptionForInput('Mongrel size'),
                e.target.value
              );
            }}
          />
        </QuestionFieldWithReducedBottomMargin>
      )}
      {showBreedInfoPanel && (
        <BreedPlaybackPanel
          pageTitle={PageTitle.AboutYouAndYourPet}
          petDetails={petDetails}
        />
      )}
    </>
  );
};

export default DogBreedQuestions;
