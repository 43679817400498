import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import { addLeadingZerosToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  FieldFunction,
  QuestionWithPlaceholderField,
  StringField,
} from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { FullAddress } from 'apiHelpers/address/address';
import mapFormToClubcardSearch from 'apiHelpers/clubcard/mapFormToClubcardSearch';
import clubcardClient from 'apiHelpers/clubcardClient';
import { AxiosError } from 'axios';
import React, { ReactElement } from 'react';
import { NameInput } from 'components/NameInput/styles';
import QuestionField from 'components/QuestionField';
import SelectInput from 'components/SelectInput';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import { StyledDateInput } from 'forms/AboutYourPetsForm/AboutYourPet/styles';
import {
  PageTitle,
  trackAPIError,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackFormTextFieldFocus,
} from 'helpers/eventTracking';
import { INPUT_REGEX_NAME, INPUT_REGEX_NAME_SPECIAL_CHARS } from 'helpers/inputRegexes';
import { useOptimizelyGradualRevealExperimentVariation } from 'helpers/optimizelyVariation';
import { capitaliseCharacterAfterHyphenAndSpace } from 'helpers/stringHelpers';
import { CustomerDetails, useCustomerDetails } from 'state/formData/customerDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import { ReferenceDataOption } from 'types/referenceData';
import AddressForm from './AddressForm';
import NumberOfPetsQuestion from './NumberOfPetsQuestion';
import { filterTitleOptions } from './filterTitleOptions';
import useAboutYouQuestions, { AboutYouQuestions } from './questions';

type AboutYouFormProps = {
  formValidation: {
    getError: FieldFunction<FormDetails, string | undefined>;
    getWarning: FieldFunction<FormDetails, string | undefined>;
    showValidation: FieldFunction<FormDetails, void>;
  };
};

const AboutYouForm: React.FC<AboutYouFormProps> = ({ formValidation }) => {
  const { getError, getWarning, showValidation } = formValidation;
  const questions = useAboutYouQuestions();
  const [customerDetails, updateCustomerDetails] = useCustomerDetails();
  const isGradulaRevealExperiment = useOptimizelyGradualRevealExperimentVariation();
  const titlesRefData = useReferenceData('titles')?.titles ?? [];
  const filteredTitlesRefData = filterTitleOptions(
    titlesRefData,
    customerDetails.customerTitle
  );

  const renderRefDataQuestion = (
    id: QuestionWithPlaceholderField<AboutYouQuestions> & StringField<CustomerDetails>,
    options: ReferenceDataOption[] | undefined,
    isPii?: boolean,
    analyticsDescription?: string
  ): ReactElement => (
    <QuestionField
      question={questions[id]}
      errorText={getError(id)}
      warningText={getWarning(id)}>
      <SelectInput
        id={id}
        value={customerDetails[id]}
        options={options ?? []}
        placeholder={questions[id].placeholder}
        onChange={(e) => {
          trackFormDropdownSelect(
            analyticsDescription || id,
            isPii ? undefined : e.target.value
          );
          updateCustomerDetails({ [id]: e.target.value });
        }}
        onBlur={() => showValidation(id)}
        onFocus={trackFormDropdownFocus(
          analyticsDescription || id,
          PageTitle.AboutYouAndYourPet
        )}
        loading={!options}
      />
    </QuestionField>
  );

  const tryClubcardSearch = async (address?: FullAddress): Promise<void> => {
    const searchParams = mapFormToClubcardSearch(
      address ? { ...customerDetails, address } : customerDetails
    );
    const { firstName, lastName, postcode } = searchParams;
    if (lastName && firstName && postcode) {
      try {
        const searchResult = await clubcardClient.search(searchParams);
        updateCustomerDetails({
          clubcardSearch: {
            status: 'SUCCESS',
            data: searchResult,
          },
          hasClubcard: 'yes',
        });
      } catch (err) {
        const error: AxiosError = err as AxiosError;
        trackAPIError(error);
        updateCustomerDetails({
          clubcardSearch: { status: 'FAILURE' },
          hasClubcard: customerDetails.clubcardLookup.data
            ? 'yes'
            : customerDetails.hasClubcard,
        });
      }
    }
  };

  return (
    <>
      {renderRefDataQuestion(
        'customerTitle',
        filteredTitlesRefData,
        false,
        'Customer title'
      )}
      <QuestionField
        question={questions.customerFirstName}
        errorText={getError('customerFirstName')}>
        <NameInput
          id="customerFirstName"
          placeholder={questions.customerFirstName.placeholder}
          maxLength={40}
          value={customerDetails.customerFirstName}
          onChange={(e) => {
            if (
              e.target.value.match(INPUT_REGEX_NAME) ||
              // Users from aggs could have special characters in their name.
              // The below allows them to edit their name if required, while restricting them only to the aggs allowed special characters.
              (!customerDetails.customerFirstName.match(INPUT_REGEX_NAME) &&
                e.target.value.match(INPUT_REGEX_NAME_SPECIAL_CHARS))
            ) {
              updateCustomerDetails({
                customerFirstName: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onBlur={() => {
            tryClubcardSearch();
            showValidation('customerFirstName');
          }}
          onFocus={trackFormTextFieldFocus('Customer first name')}
        />
      </QuestionField>
      <QuestionField
        question={questions.customerLastName}
        errorText={getError('customerLastName')}>
        <NameInput
          id="customerLastName"
          placeholder={questions.customerLastName.placeholder}
          maxLength={30}
          value={customerDetails.customerLastName}
          onChange={(e) => {
            if (
              e.target.value.match(INPUT_REGEX_NAME) ||
              // Users from aggs could have special characters in their name.
              // The below allows them to edit their name if required, while restricting them only to the aggs allowed special characters.
              (!customerDetails.customerLastName.match(INPUT_REGEX_NAME) &&
                e.target.value.match(INPUT_REGEX_NAME_SPECIAL_CHARS))
            ) {
              updateCustomerDetails({
                customerLastName: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onBlur={() => {
            tryClubcardSearch();
            showValidation('customerLastName');
          }}
          onFocus={trackFormTextFieldFocus('Customer last name')}
        />
      </QuestionField>
      <QuestionField question={questions.customerDob} errorText={getError('customerDob')}>
        <StyledDateInput
          id="customerDob"
          value={customerDetails.customerDob}
          onChange={(e) =>
            updateCustomerDetails({
              customerDob: e,
            })
          }
          onBlur={() => {
            updateCustomerDetails({
              customerDob: addLeadingZerosToDateValue(customerDetails.customerDob),
            });
            showValidation('customerDob');
          }}
          onFocus={trackFormTextFieldFocus('Customer DOB')}
        />
      </QuestionField>
      <QuestionField
        question={questions.customerEmail}
        errorText={getError('customerEmail')}>
        <TextInput
          id="customerEmail"
          placeholder={questions.customerEmail.placeholder}
          maxLength={254}
          value={customerDetails.customerEmail}
          onChange={(e) => {
            updateCustomerDetails({
              customerEmail: e.target.value.replaceAll(' ', ''),
            });
          }}
          onBlur={() => showValidation('customerEmail')}
          onFocus={trackFormTextFieldFocus('Customer email')}
          inputMode="email"
        />
      </QuestionField>
      <QuestionField
        question={questions.customerTelephone}
        errorText={getError('customerTelephone')}>
        <NameInput
          id="customerTelephone"
          placeholder={questions.customerTelephone.placeholder}
          maxLength={11}
          value={customerDetails.customerTelephone}
          onChange={(e) => {
            updateCustomerDetails({
              customerTelephone: e.target.value,
            });
          }}
          numbersOnly
          onBlur={() => showValidation('customerTelephone')}
          onFocus={trackFormTextFieldFocus('Customer telephone')}
          inputMode="tel"
        />
      </QuestionField>
      <AddressForm
        addressDetails={customerDetails}
        updateAddressDetails={updateCustomerDetails}
        formValidation={formValidation}
        tryClubcardSearch={tryClubcardSearch}
      />
      {!isGradulaRevealExperiment && (
        <NumberOfPetsQuestion
          formValidation={{
            getError,
            getWarning,
            showValidation,
          }}
        />
      )}
    </>
  );
};

export default AboutYouForm;
