import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import QuestionField from 'components/QuestionField';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import { DesignConstancts } from 'helpers/businessConstants';
import {
  PageTitle,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackRadioButtonClick,
} from 'helpers/eventTracking';
import getPetIdForInput from 'helpers/getPetId';
import { INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE } from 'helpers/inputRegexes';
import {
  catBreed_MOGGY,
  catBreedType_NON_PEDIGREE,
  catBreedType_PEDIGREE,
  petType_CAT,
} from 'helpers/referenceDataConstants';
import { Pet, PetWithKey } from 'state/formData/petsDetails';
import { ReferenceDataOption } from 'types/referenceData';
import { useCatBreedTypeOptions } from '../../useCatBreedTypeOptions';
import { StyledTypeaheadInput } from '../DogBreedQuestions/styles';
import useAboutYourPetQuestions from '../questions';

type CatBreedQuestionsProps = {
  petDetails: Pet;
  updatePetDetails: (update: Partial<PetWithKey>) => void;
  index: number;
  formValidation: {
    getError: FieldFunction<FormDetails, string | undefined>;
    showValidation: FieldFunction<FormDetails, void>;
  };
  catBreedReferenceData: ReferenceDataOption[];
};

const CatBreedQuestions: React.FC<CatBreedQuestionsProps> = ({
  petDetails,
  updatePetDetails,
  index,
  formValidation: { getError, showValidation },
  catBreedReferenceData,
}) => {
  const questions = useAboutYourPetQuestions(petDetails);

  const getIdForInput = getPetIdForInput(index);
  const getAnalyticsDescriptionForInput = (input: string): string =>
    `Pet ${index} - ${input}`;

  const catBreedTypeOptions = useCatBreedTypeOptions();

  const showCatQuestions = petDetails.petType === petType_CAT;
  const showPedigreeCatBreedNameQuestion =
    showCatQuestions && petDetails.catBreedType === catBreedType_PEDIGREE;
  const showCatBreedUnknownQuestion =
    showCatQuestions && petDetails.catBreedType === catBreedType_NON_PEDIGREE;
  const showNonPedigreeCatBreedNameQuestion =
    showCatBreedUnknownQuestion && petDetails.catBreedUnknown === false;

  const nonUnknownBreedNameQuestion = showPedigreeCatBreedNameQuestion
    ? questions.pedigreeCatBreedName
    : questions.nonPedigreeCatBreedName;

  // Dynamically set petDetails property key to use for cat breed data:
  // - Pedigree     => petDetails.catPedigreeBreedName
  // - Non Pedigree => petDetails.catCrossBreedName
  const catBreedNamePropertyKey = showPedigreeCatBreedNameQuestion
    ? 'catPedigreeBreedName'
    : 'catNonPedigreeBreedName';

  const nonUnknownBreedNameAnalyticsDescription = showPedigreeCatBreedNameQuestion
    ? 'Pedigree cat breed name'
    : 'Non pedigree cat breed name';

  const catBreedNameMappedOptions = catBreedReferenceData.map((option) => ({
    id: option.value,
    label: option.name,
  }));

  /* Filtering moggy and moggie out of the list of pedigree breeds to prevent a user selecting a 
   pedigree moggy */
  const catBreedPedigreeMappedOptions = catBreedNameMappedOptions.filter(
    (breed) => breed.label !== 'Moggy' && breed.label !== 'Moggie'
  );

  const menuMaxHeight = DesignConstancts.PET_BREED_DROPDOWN_MENU_MAX_HEIGHT;

  const breedNameQuestionField = (
    <QuestionField
      question={nonUnknownBreedNameQuestion}
      errorText={getError('petDetails', [catBreedNamePropertyKey, index])}>
      <StyledTypeaheadInput
        id={getIdForInput(catBreedNamePropertyKey)}
        value={catBreedNameMappedOptions.find(
          (option) => option.id === petDetails[catBreedNamePropertyKey]
        )}
        options={
          (petDetails.catBreedType === catBreedType_PEDIGREE
            ? catBreedPedigreeMappedOptions
            : catBreedNameMappedOptions) ?? []
        }
        placeholder={nonUnknownBreedNameQuestion.placeholder}
        menuMaxHeight={menuMaxHeight}
        onChange={(option) => {
          updatePetDetails({
            [catBreedNamePropertyKey]: option?.id,
          });
          trackFormDropdownSelect(
            getAnalyticsDescriptionForInput(nonUnknownBreedNameAnalyticsDescription),
            option?.label ?? 'none'
          );
        }}
        onBlur={() => showValidation('petDetails', [catBreedNamePropertyKey, index])}
        onFocus={trackFormDropdownFocus(
          getAnalyticsDescriptionForInput(nonUnknownBreedNameAnalyticsDescription),
          PageTitle.AboutYouAndYourPet
        )}
        onKeyPress={(e) => {
          if (!e.key.match(INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE)) {
            e.preventDefault();
          }
        }}
      />
    </QuestionField>
  );

  return (
    <>
      <QuestionField
        question={questions.catBreedType}
        errorText={getError('petDetails', ['catBreedType', index])}
        initiallyShowTooltip
        data-cy="catBreedTypeField">
        <RadioInput
          id={getIdForInput('catBreedType')}
          value={petDetails.catBreedType}
          options={catBreedTypeOptions}
          onChange={(e) => {
            updatePetDetails({
              catBreedType: e.target.value,
              [catBreedNamePropertyKey]: '',
            });
            trackRadioButtonClick(
              getAnalyticsDescriptionForInput('Cat breed type'),
              e.target.value
            );
          }}
        />
      </QuestionField>
      {showCatBreedUnknownQuestion && (
        <QuestionField
          question={questions.catBreedUnknown}
          errorText={getError('petDetails', ['catBreedUnknown', index])}>
          <BooleanRadioInput
            id={getIdForInput('catBreedUnknown')}
            analyticsDescription={getAnalyticsDescriptionForInput('Is cat breed unknown')}
            value={petDetails.catBreedUnknown}
            onChange={(value) => {
              updatePetDetails({
                catBreedUnknown: value,
                catPedigreeBreedName: '',
                catNonPedigreeBreedName: value ? catBreed_MOGGY : '',
              });
            }}
          />
        </QuestionField>
      )}
      {showPedigreeCatBreedNameQuestion && breedNameQuestionField}
      {showNonPedigreeCatBreedNameQuestion && breedNameQuestionField}
    </>
  );
};

export default CatBreedQuestions;
