import { AddressInfo } from 'state/formData/customerDetails';
import { FullAddress } from './address/address';
import { petApiService } from './apiService';

type AddressLookupClient = {
  addressSearch: (postcode: string) => Promise<AddressInfo[]>;
  getFullAddress: (postcode: string, id?: string) => Promise<FullAddress>;
};

const addressLookupClient: AddressLookupClient = {
  addressSearch: async (postcode: string) =>
    petApiService.get<AddressInfo[]>('address/lookup', {
      postcode,
    }),
  getFullAddress: (postcode: string, id?: string) =>
    petApiService.get<FullAddress>('address', {
      id,
      postcode,
    }),
};

export default addressLookupClient;
