import RepeatableSection from '@rsa-digital/evo-shared-components/components/Form/RepeatableSection';
import { scrollAndFocusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { navigate } from 'gatsby';
import React from 'react';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import { addItem, removeItem, updateItem } from 'helpers/arrayHelper';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { useValidateAboutYouForm } from 'helpers/revealFormValidation';
import useAge8ExcessAgreements from 'helpers/useAge8ExcessAgreements';
import { initialPet, usePetsDetails } from 'state/formData/petsDetails';
import AboutYourPetForm from './AboutYourPet';
import { StyledAdditionalFormSectionBanner } from './styles';
import { usePetDetailsOptions } from './usePetsDetailsOptions';

type AboutYourPetsFormProps = {
  formValidation: {
    getError: FieldFunction<FormDetails, string | undefined>;
    getWarning: FieldFunction<FormDetails, string | undefined>;
    showValidation: FieldFunction<FormDetails, void>;
    resetValidation: FieldFunction<FormDetails, void>;
  };
  allPetsAreEligible: boolean;
  allPetsAreHealthy: boolean;
};

const AboutYourPetsForm: React.FC<AboutYourPetsFormProps> = ({
  formValidation,
  allPetsAreEligible,
  allPetsAreHealthy,
}) => {
  const [petsDetails, updatePetsDetails] = usePetsDetails();
  const {
    defaultSectionHeadings,
    removePetButtonTexts,
    addPetButtonText,
    addPetBanner,
    threePetsAddedBanner,
    petSectionHeading,
  } = usePetDetailsOptions();

  const generateSectionLabel = (index: number): string => {
    const { petName } = petsDetails[index];
    return petName
      ? petNameReplacer(petName, petSectionHeading)
      : defaultSectionHeadings[index];
  };

  const { updateAge8ExcessAgreements } = useAge8ExcessAgreements();
  const validateAboutYouFormFields = useValidateAboutYouForm();

  return (
    <>
      <RepeatableSection
        onAdd={() => {
          trackTextButtonClick(PageTitle.AboutYouAndYourPet, addPetButtonText);
          updatePetsDetails(addItem(petsDetails, initialPet));
        }}
        onRemove={(index) => {
          trackTextButtonClick(PageTitle.AboutYouAndYourPet, removePetButtonTexts[index]);
          const currentPets = petsDetails;
          const updatedPets = removeItem(currentPets, index);
          updatePetsDetails(updatedPets);
          if (updatedPets.length === 1) {
            navigate('#add-pet-banner');
          } else if (updatedPets.length === 2) {
            navigate(`#addItem${updatedPets.length - 1}`);
          }
        }}
        // We disable the add button for 1 pet in order to use the alternate 'add pet banner' instead of the default button
        disableAdd={petsDetails.length >= 3 || petsDetails.length === 1}
        label={generateSectionLabel}
        addText={addPetButtonText}
        removeText={(index) => removePetButtonTexts[index]}
        alignLeft>
        {petsDetails.map((pet, index) => (
          <AboutYourPetForm
            key={pet.key}
            index={index}
            petDetails={pet}
            updatePetDetails={(update) => {
              updatePetsDetails(
                updateItem(petsDetails, index, {
                  ...update,
                })
              );
              updateAge8ExcessAgreements({ petOver8: undefined, petUnder8: undefined });
            }}
            formValidation={formValidation}
          />
        ))}
      </RepeatableSection>
      {validateAboutYouFormFields &&
        allPetsAreEligible &&
        allPetsAreHealthy &&
        petsDetails.length === 1 && (
          <StyledAdditionalFormSectionBanner
            data-cy="addPetBanner"
            id="add-pet-banner"
            headingText={addPetBanner.headingText}
            bodyText={addPetBanner.bodyText}
            buttonText={addPetBanner.buttonText}
            buttonAriaLabel={addPetBanner.buttonAriaLabel}
            buttonId="addPetButton"
            addFormSectionButtonClick={() => {
              updatePetsDetails(addItem(petsDetails, initialPet));
              /* This use of setTimeout forces scrollAndFocusInput to be called after
               * updatePetsDetails, so that we attempt to scroll to the newly added pet
               * form section *after* the section has been rendered in the DOM.
               *
               * See https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful for more
               * details about this implementation.
               */
              setTimeout(() => scrollAndFocusInput(`petDetails[${petsDetails.length}]`));
              trackTextButtonClick(
                PageTitle.AboutYouAndYourPet,
                'Add a pet - pet details'
              );
            }}
          />
        )}
      {validateAboutYouFormFields && petsDetails.length === 3 && (
        <StyledAdditionalFormSectionBanner
          data-cy="threePetsAddedBanner"
          headingText={threePetsAddedBanner.bannerHeadingText}
          bodyText={threePetsAddedBanner.bannerBodyText}
        />
      )}
    </>
  );
};

export default AboutYourPetsForm;
