import { graphql, useStaticQuery } from 'gatsby';

type CsPetDetailsOptions = {
  csPetAboutYouAndYourPet: {
    about_your_pet_section: {
      pet_section_heading: string;
      add_pet_button_text: string;
      default_section_headings: {
        first_pet_section_default_text: string;
        second_pet_section_default_text: string;
        third_pet_section_default_text: string;
      };
      remove_button_texts: {
        remove_first_pet_button_text: string;
        remove_second_pet_button_text: string;
        remove_third_pet_button_text: string;
      };
      add_pet_banner: {
        heading_text: string;
        body_text: string;
        button_text: string;
        button_aria_label: string;
      };
      three_pets_added_banner: {
        banner_heading_text: string;
        banner_body_text: string;
      };
    };
  };
};

type PetDetailsOptions = {
  petSectionHeading: string;
  addPetButtonText: string;
  addPetBanner: {
    headingText: string;
    bodyText: string;
    buttonText: string;
    buttonAriaLabel: string;
  };
  threePetsAddedBanner: {
    bannerHeadingText: string;
    bannerBodyText: string;
  };
  defaultSectionHeadings: string[];
  removePetButtonTexts: string[];
};

const query = graphql`
  query {
    csPetAboutYouAndYourPet {
      about_your_pet_section {
        pet_section_heading
        add_pet_button_text
        default_section_headings {
          first_pet_section_default_text
          second_pet_section_default_text
          third_pet_section_default_text
        }
        remove_button_texts {
          remove_first_pet_button_text
          remove_second_pet_button_text
          remove_third_pet_button_text
        }
        add_pet_banner {
          heading_text
          body_text
          button_text
          button_aria_label
        }
        three_pets_added_banner {
          banner_heading_text
          banner_body_text
        }
      }
    }
  }
`;

export const usePetDetailsOptions = (): PetDetailsOptions => {
  const {
    pet_section_heading,
    add_pet_button_text,
    add_pet_banner: { heading_text, body_text, button_text, button_aria_label },
    three_pets_added_banner: { banner_heading_text, banner_body_text },
    default_section_headings: {
      first_pet_section_default_text,
      second_pet_section_default_text,
      third_pet_section_default_text,
    },
    remove_button_texts: {
      remove_first_pet_button_text,
      remove_second_pet_button_text,
      remove_third_pet_button_text,
    },
  } = useStaticQuery<CsPetDetailsOptions>(
    query
  ).csPetAboutYouAndYourPet.about_your_pet_section;
  return {
    petSectionHeading: pet_section_heading,
    addPetButtonText: add_pet_button_text,
    addPetBanner: {
      headingText: heading_text,
      bodyText: body_text,
      buttonText: button_text,
      buttonAriaLabel: button_aria_label,
    },
    threePetsAddedBanner: {
      bannerHeadingText: banner_heading_text,
      bannerBodyText: banner_body_text,
    },
    defaultSectionHeadings: [
      first_pet_section_default_text,
      second_pet_section_default_text,
      third_pet_section_default_text,
    ],
    removePetButtonTexts: [
      remove_first_pet_button_text,
      remove_second_pet_button_text,
      remove_third_pet_button_text,
    ],
  };
};
