import { graphql, useStaticQuery } from 'gatsby';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { CsIcon } from 'types/contentStack';

export type dogBreedEligibilityStatement = {
  heading: string;
  statement_list: string;
  customBulletIcon: string | undefined;
  panel_icon: string | undefined;
};

type CsDogBreedEligibilityStatement = {
  csPetAboutYourPetDogQuestions: {
    dog_eligibility_question: {
      heading: string;
      statement_list: string;
      custom_bullet_icon: [CsIcon];
      panel_icon: [CsIcon];
    };
  };
};

const query = graphql`
  query {
    csPetAboutYourPetDogQuestions {
      dog_eligibility_question {
        heading
        statement_list
        custom_bullet_icon {
          icon_code
        }
        panel_icon {
          icon_code
        }
      }
    }
  }
`;

export const useDogEligibilityStatement = (): dogBreedEligibilityStatement => {
  const eligibilityStatement = useStaticQuery<CsDogBreedEligibilityStatement>(query)
    .csPetAboutYourPetDogQuestions.dog_eligibility_question;
  const icon = unwrapSingleton(eligibilityStatement.custom_bullet_icon)?.icon_code;
  const panel_icon = unwrapSingleton(eligibilityStatement.panel_icon)?.icon_code;
  return {
    heading: eligibilityStatement.heading,
    statement_list: eligibilityStatement.statement_list,
    customBulletIcon: icon,
    panel_icon,
  };
};
