import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { LookupStatus } from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { graphql, useStaticQuery } from 'gatsby';
import { PolicyDetails } from 'state/formData/policyDetails';

type csAboutYourPolicyErrorMessages = {
  csPetAboutYourPolicyQuestions: {
    promo_code: {
      error_messages: {
        unapplied_promo_code: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYourPolicyQuestions {
      promo_code {
        error_messages {
          unapplied_promo_code
        }
      }
    }
  }
`;

export const isPromoCodeBlank = (policyDetails: PolicyDetails): boolean =>
  !policyDetails.promoCode.lookupKey;

const useAboutYourPolicyRules = (): ValidationRules<PolicyDetails> => {
  const { error_messages } = useStaticQuery<csAboutYourPolicyErrorMessages>(
    query
  ).csPetAboutYourPolicyQuestions.promo_code;
  return {
    promoCode: [
      {
        validityCondition: (value, data) =>
          isPromoCodeBlank(data) || value.status !== LookupStatus.Pending,
        errorMessage: error_messages.unapplied_promo_code,
        onlyValidateAfterSubmission: true,
      },
    ],
  };
};

export default useAboutYourPolicyRules;
