import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import LoadQuoteInProgressWrapper from 'components/ApiRequestWrapper/LoadQuoteInProgressWrapper';
import Layout from 'components/Layout';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import AboutYouAndYourPetForm from 'forms/AboutYouAndYourPetForm';
import { getCookie, setCookie } from 'helpers/cookieHelpers';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import { PageTitle } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import {
  useResetTescoPageLoadTrackingOnRefresh,
  useTescoPageLoadTracking,
  useTescoPageViewTracking,
} from 'helpers/pageTrackingForTesco';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import {
  removeData,
  TESCO_PAGE_VIEW_IS_RETRIEVED_QUOTE,
} from 'helpers/sessionStorageHelpers';
import { useAnchorToSection } from 'helpers/useAnchorToSection';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsHero } from 'types/contentStack';
import { ImportantInformation } from './styles';

const STEP = 1;

type AboutYouAndYourPetProps = {
  data: {
    csPetAboutYouAndYourPet: {
      meta_title: string;
      hero: CsHero;
      important_information: string;
    };
  };
};

export const query = graphql`
  query {
    csPetAboutYouAndYourPet {
      meta_title
      hero {
        heading
        subheading
      }
      important_information
    }
  }
`;

const AboutYouAndYourPet: React.FC<AboutYouAndYourPetProps> = ({
  data: {
    csPetAboutYouAndYourPet: { important_information, hero, meta_title },
  },
}) => {
  useAnchorToSection();

  const moveNext = (): void => {
    navigate(quoteAndBuyRoutes.loadingQuote);
  };

  if (getCookie('ens_gpv_pn') === '') {
    setCookie('ens_gpv_pn', 'Entry');
  }

  const quote = useCurrentQuote();
  /* this has been moved to the LoadQuoteInProgressWrapper - as per Elliot's comments on https://jira2.uk.rsa-ins.com/browse/EP-1477
   * we want this tracking to happen *before* the tracking for the loading spinner. The loadQuoteInProgressWrapper component is only
   * used on this page, so we don't need to worry about this change causing extra tracking
   */
  usePageTracking(meta_title);

  useTescoPageLoadTracking();
  useResetTescoPageLoadTrackingOnRefresh();

  // The following is necessary in case the user follows an unsual journey path such as:
  // Tesco Bank > Retrieve Quote page > BACK > Start New Quote (About you and your pet)
  removeData(TESCO_PAGE_VIEW_IS_RETRIEVED_QUOTE);

  useTescoPageViewTracking();
  useCheckoutTracking(STEP, quote, false);

  return (
    <LoadQuoteInProgressWrapper metaTitle={meta_title}>
      <Layout
        pageTitle={PageTitle.AboutYouAndYourPet}
        currentStep={QuoteAndBuyStep.AboutYouAndYourPet}
        heading={hero.heading}
        subheading={hero.subheading}
        metaTitle={meta_title}>
        <Grid alignLeft>
          <GridItem desktop={9} tabletLandscape={9} tabletPortrait={7} mobile={4}>
            <ImportantInformation
              pageTitle={PageTitle.AboutYouAndYourPet}
              data-cy="important_information"
              html={important_information}
            />
          </GridItem>
        </Grid>
        <AboutYouAndYourPetForm moveNext={moveNext} />
      </Layout>
    </LoadQuoteInProgressWrapper>
  );
};

export default AboutYouAndYourPet;
