import { graphql, useStaticQuery } from 'gatsby';
import { trimEnd } from 'lodash';
import { PageTitle } from 'helpers/eventTracking';
import { usePetNameReplacerWithDefault } from 'helpers/placeholders/dynamicPetNameHelpers';
import { replacePlaceholdersQuestion } from 'helpers/placeholders/replaceCsPlaceholders';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { Pet } from 'state/formData/petsDetails';
import { CsQuestion, CsQuestionWithPlaceholder } from 'types/contentStack';
import { Question, QuestionWithOptionalPlaceholder } from 'types/forms';

type AboutYourPetQuestions = {
  petName: QuestionWithOptionalPlaceholder;
  petType: Question;
  dogEligibilityQuestion: Question;
  dogBreedType: Question;
  mongrelSize: Question;
  pedigreeDogBreedName: QuestionWithOptionalPlaceholder;
  crossBreedDogBreedName: QuestionWithOptionalPlaceholder;
  catEligibilityQuestion: Question;
  catBreedType: Question;
  pedigreeCatBreedName: QuestionWithOptionalPlaceholder;
  catBreedUnknown: Question;
  nonPedigreeCatBreedName: QuestionWithOptionalPlaceholder;
  petGender: Question;
  petDob: Question;
  petCost: { noCostButtonText: string } & QuestionWithOptionalPlaceholder;
  petSpayed: Question;
  petChipped: Question;
  petLivesWithYou: Question;
  petInGoodHealth: Question;
};

type csAboutYourPetQuestions = {
  csPetAboutYourPetMainQuestions: {
    pet_name: CsQuestionWithPlaceholder;
    pet_type: CsQuestion;
    pet_gender: CsQuestion;
    pet_date_of_birth: CsQuestion;
    pet_cost: { no_cost_button_text: string } & CsQuestionWithPlaceholder;
    pet_spayed: CsQuestion;
    pet_chipped: CsQuestion;
    pet_lives_with_you: CsQuestion;
    pet_in_good_health: CsQuestion;
  };
  csPetAboutYourPetCatQuestions: {
    cat_eligibility_question: CsQuestion;
    cat_breed_type: CsQuestion;
    cat_breed_name_pedigree: CsQuestionWithPlaceholder;
    cat_breed_name_non_pedigree: CsQuestionWithPlaceholder;
    cat_breed_unknown: CsQuestion;
  };
  csPetAboutYourPetDogQuestions: {
    dog_eligibility_question: CsQuestion;
    dog_breed_type: CsQuestion;
    pedigree_dog_breed_name: CsQuestionWithPlaceholder;
    cross_breed_dog_breed_name: CsQuestionWithPlaceholder;
    mongrel_size: CsQuestion;
  };
};

const query = graphql`
  query {
    csPetAboutYourPetMainQuestions {
      pet_name {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      pet_type {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      pet_gender {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      pet_date_of_birth {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      pet_cost {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
        no_cost_button_text
      }
      pet_spayed {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      pet_chipped {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      pet_lives_with_you {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      pet_in_good_health {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
    }
    csPetAboutYourPetCatQuestions {
      cat_eligibility_question {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      cat_breed_type {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      cat_breed_name_pedigree {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      cat_breed_name_non_pedigree {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      cat_breed_unknown {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
    }
    csPetAboutYourPetDogQuestions {
      dog_eligibility_question {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      dog_breed_type {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      pedigree_dog_breed_name {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      cross_breed_dog_breed_name {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      mongrel_size {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
    }
  }
`;

const useAboutYourPetQuestions = (pet: Pet): AboutYourPetQuestions => {
  const csQuestions = useStaticQuery<csAboutYourPetQuestions>(query);
  const {
    processQuestion,
    processQuestionWithOptionalPlaceholder,
  } = useQuestionProcessor(PageTitle.AboutYouAndYourPet);

  const petNameReplacer = usePetNameReplacerWithDefault(trimEnd(pet.petName));
  const substitutePetName = replacePlaceholdersQuestion(petNameReplacer);

  return {
    petName: processQuestionWithOptionalPlaceholder(
      csQuestions.csPetAboutYourPetMainQuestions.pet_name
    ),
    petType: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetMainQuestions.pet_type)
    ),
    dogEligibilityQuestion: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetDogQuestions.dog_eligibility_question)
    ),
    dogBreedType: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetDogQuestions.dog_breed_type)
    ),
    pedigreeDogBreedName: substitutePetName(
      processQuestionWithOptionalPlaceholder(
        csQuestions.csPetAboutYourPetDogQuestions.pedigree_dog_breed_name
      )
    ),
    crossBreedDogBreedName: substitutePetName(
      processQuestionWithOptionalPlaceholder(
        csQuestions.csPetAboutYourPetDogQuestions.cross_breed_dog_breed_name
      )
    ),
    mongrelSize: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetDogQuestions.mongrel_size)
    ),
    catEligibilityQuestion: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetCatQuestions.cat_eligibility_question)
    ),
    catBreedType: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetCatQuestions.cat_breed_type)
    ),
    pedigreeCatBreedName: substitutePetName(
      processQuestionWithOptionalPlaceholder(
        csQuestions.csPetAboutYourPetCatQuestions.cat_breed_name_pedigree
      )
    ),
    catBreedUnknown: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetCatQuestions.cat_breed_unknown)
    ),
    nonPedigreeCatBreedName: substitutePetName(
      processQuestionWithOptionalPlaceholder(
        csQuestions.csPetAboutYourPetCatQuestions.cat_breed_name_non_pedigree
      )
    ),
    petGender: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetMainQuestions.pet_gender)
    ),
    petDob: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetMainQuestions.pet_date_of_birth)
    ),
    petCost: {
      ...substitutePetName(
        processQuestionWithOptionalPlaceholder(
          csQuestions.csPetAboutYourPetMainQuestions.pet_cost
        )
      ),
      noCostButtonText:
        csQuestions.csPetAboutYourPetMainQuestions.pet_cost.no_cost_button_text,
    },
    petSpayed: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetMainQuestions.pet_spayed)
    ),
    petChipped: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetMainQuestions.pet_chipped)
    ),
    petLivesWithYou: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetMainQuestions.pet_lives_with_you)
    ),
    petInGoodHealth: substitutePetName(
      processQuestion(csQuestions.csPetAboutYourPetMainQuestions.pet_in_good_health)
    ),
  };
};

export default useAboutYourPetQuestions;
