import { isInt } from 'helpers/integerHelpers';
import { CustomerDetails } from 'state/formData/customerDetails';
import { ClubcardSearchQueryParams } from './search';

const mapAddress = (
  customerDetails: CustomerDetails
): Omit<ClubcardSearchQueryParams, 'firstName' | 'lastName'> => {
  const { address } = customerDetails;
  return customerDetails.isManualAddress
    ? {
        street: customerDetails.street,
        postcode: customerDetails.postcode,
        ...(isInt(customerDetails.flatNameOrNumber)
          ? { flatNumber: customerDetails.flatNameOrNumber }
          : { flatName: customerDetails.flatNameOrNumber }),
        ...(isInt(customerDetails.houseNameOrNumber)
          ? { houseNumber: customerDetails.houseNameOrNumber }
          : { houseName: customerDetails.houseNameOrNumber }),
      }
    : {
        flatName: address?.flatName ?? undefined,
        flatNumber: address?.flatNumber ?? undefined,
        houseName: address?.houseName ?? undefined,
        houseNumber: address?.houseNumber ?? undefined,
        street: address?.street ?? undefined,
        postcode: address?.postcode ?? undefined,
      };
};

const mapFormToClubcardSearch = (
  customerDetails: CustomerDetails
): ClubcardSearchQueryParams => {
  return {
    firstName: customerDetails.customerFirstName,
    lastName: customerDetails.customerLastName,
    ...mapAddress(customerDetails),
  };
};

export default mapFormToClubcardSearch;
