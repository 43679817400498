import FieldGrid from '@rsa-digital/evo-shared-components/components/Form/Field/FieldGrid';
import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { addLeadingZerosToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import {
  getYoungestPetAgeThresholdDate,
  useFirstEligibleStartDateFromToday,
} from 'businessLogic/petAge';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import { BooleanRadioWithUnknownInput } from 'components/BooleanWithUnknownRadioInput';
import CostInput from 'components/CostInput';
import { NameInput } from 'components/NameInput/styles';
import QuestionField from 'components/QuestionField';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import {
  PageTitle,
  trackFormTextFieldFocus,
  trackRadioButtonClick,
} from 'helpers/eventTracking';
import getPetIdForInput from 'helpers/getPetId';
import {
  INPUT_REGEX_PET_NAME,
  INPUT_REGEX_PET_NAME_SPECIAL_CHARS,
} from 'helpers/inputRegexes';
import { useOptimizelyGradualRevealExperimentVariation } from 'helpers/optimizelyVariation';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { capitaliseCharacterAfterHyphenAndSpace } from 'helpers/stringHelpers';
import { useTouchAssumption } from 'state/formData/assumptionsAgreement';
import { initialPet, Pet, PetWithKey } from 'state/formData/petsDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import CatBreedQuestions from './CatBreedQuestions';
import DogBreedQuestions from './DogBreedQuestions';
import useAboutYourPetQuestions from './questions';
import {
  IconWrapper,
  StyledDateInput,
  StyledPanel,
  StyledPanelHeading,
  StyledPanelHeadingContainer,
  StyledQuestionField,
  StyledQuestionFieldWithMarginTop,
  StyledRichTextWithModal,
} from './styles';
import { useCatEligibilityStatement } from '../useCatEligibilityStatement';
import { useDogEligibilityStatement } from '../useDogEligibilityStatement';

type AboutYourPetFormProps = {
  petDetails: Pet;
  updatePetDetails: (update: Partial<PetWithKey>) => void;
  index: number;
  formValidation: {
    getError: FieldFunction<FormDetails, string | undefined>;
    getWarning: FieldFunction<FormDetails, string | undefined>;
    showValidation: FieldFunction<FormDetails, void>;
    resetValidation: FieldFunction<FormDetails, void>;
  };
};

const AboutYourPetForm: React.FC<AboutYourPetFormProps> = ({
  petDetails,
  updatePetDetails,
  index,
  formValidation: { getError, getWarning, showValidation, resetValidation },
}) => {
  const questions = useAboutYourPetQuestions(petDetails);
  const dogEligibilityStatement = useDogEligibilityStatement();
  const catEligibilityStatement = useCatEligibilityStatement();
  const [policyDetails, updatePolicyDetails] = usePolicyDetails();
  const touchAssumption = useTouchAssumption();
  const isGradulaRevealExperiment = useOptimizelyGradualRevealExperimentVariation();

  const getIdForInput = getPetIdForInput(index);
  const getAnalyticsDescriptionForInput = (input: string): string =>
    `Pet ${index} - ${input}`;

  const gendersRefData = useReferenceData('genders');
  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];

  const petIsDog = petDetails.petType === petType_DOG;
  const petIsCat = petDetails.petType === petType_CAT;

  const { petIsEligible } = petDetails;

  const showDogQuestions = petIsDog && petIsEligible && petDetails.petInGoodHealth;
  const showCatQuestions = petIsCat && petIsEligible && petDetails.petInGoodHealth;

  const [firstEligibleStartDate] = useFirstEligibleStartDateFromToday();

  const updateCoverStartDate = (pets: Pet[]): Date => {
    const thresholdDate = getYoungestPetAgeThresholdDate(pets);
    if (thresholdDate && thresholdDate > new Date(policyDetails.coverStartDate)) {
      return firstEligibleStartDate;
    }
    return new Date(policyDetails.coverStartDate);
  };

  return (
    <div id={`petDetails[${index}]`}>
      <QuestionField
        question={questions.petName}
        errorText={getError('petDetails', ['petName', index])}>
        <NameInput
          id={getIdForInput('petName')}
          value={petDetails.petName}
          placeholder={questions.petName.placeholder}
          onChange={(e) => {
            if (
              !e.target.value.endsWith('  ') &&
              (e.target.value.match(INPUT_REGEX_PET_NAME) ||
                // Users from aggs could have special characters in their pet's name.
                // The below allows them to edit their name if required, while restricting them only to the aggs allowed special characters.
                (!petDetails.petName.match(INPUT_REGEX_PET_NAME) &&
                  e.target.value.match(INPUT_REGEX_PET_NAME_SPECIAL_CHARS)))
            ) {
              updatePetDetails({
                petName: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onBlur={(e) => {
            updatePetDetails({
              petName: e.target.value.trim().replace(/\s{2,}/g, ' '),
            });
            showValidation('petDetails', ['petName', index]);
          }}
          onFocus={trackFormTextFieldFocus(getAnalyticsDescriptionForInput('Pet name'))}
          maxLength={45}
        />
      </QuestionField>
      <QuestionField
        question={questions.petType}
        errorText={getError('petDetails', ['petType', index])}>
        <RadioInput
          id={getIdForInput('petType')}
          value={petDetails.petType}
          options={[
            { name: 'Dog', value: petType_DOG },
            { name: 'Cat', value: petType_CAT },
          ]}
          onChange={(e) => {
            const { petName } = petDetails;
            // We do this so that if generic pet questions are answered before petType is selected
            // the inputs are unchanged, but if the petType is changed after initial selection all
            // inputs below the petType question are cleared.
            const petDetailsToUpdate =
              petDetails.petType === ''
                ? {
                    petType: e.target.value,
                  }
                : {
                    ...initialPet,
                    petType: e.target.value,
                    petName,
                  };
            updatePetDetails(petDetailsToUpdate);
            resetValidation('petDetails', ['petIsEligible', index]);
            trackRadioButtonClick(
              getAnalyticsDescriptionForInput('Pet type'),
              e.target.value
            );
          }}
        />
      </QuestionField>
      {petIsDog && !!petDetails.petName && (
        <FieldGrid alignLeft>
          <StyledPanel>
            <Grid>
              <GridItem>
                <StyledPanelHeadingContainer>
                  <IconWrapper>
                    <Icon
                      size="large"
                      name={dogEligibilityStatement.panel_icon ?? ''}
                      color={colors.core01}
                      displayVariant
                    />
                  </IconWrapper>
                  <StyledPanelHeading>
                    {petNameReplacer(petDetails.petName, dogEligibilityStatement.heading)}
                  </StyledPanelHeading>
                </StyledPanelHeadingContainer>
              </GridItem>
              <GridItem>
                <StyledRichTextWithModal
                  pageTitle={PageTitle.AboutYouAndYourPet}
                  html={petNameReplacer(
                    petDetails.petName,
                    dogEligibilityStatement.statement_list
                  )}
                  customBulletIcon={dogEligibilityStatement.customBulletIcon}
                />
              </GridItem>
              <GridItem>
                <StyledQuestionField
                  question={questions.dogEligibilityQuestion}
                  errorText={getError('petDetails', ['petIsEligible', index])}
                  warningText={getWarning('petDetails', ['petIsEligible', index])}>
                  <BooleanRadioInput
                    buttonsPerRow={{ mobile: 1 }}
                    id={getIdForInput('petIsEligible')}
                    analyticsDescription={getAnalyticsDescriptionForInput(
                      'Agree with all statements about pet'
                    )}
                    value={petDetails.petIsEligible}
                    onChange={(value) => {
                      updatePetDetails({
                        petIsEligible: value,
                        petChipped: value === true ? 'yes' : '',
                      });
                      showValidation('petDetails', ['petIsEligible', index]);
                    }}
                  />
                </StyledQuestionField>
              </GridItem>
            </Grid>
          </StyledPanel>
        </FieldGrid>
      )}
      {petIsCat && !!petDetails.petName && (
        <FieldGrid alignLeft>
          <StyledPanel>
            <Grid>
              <GridItem>
                <StyledPanelHeadingContainer>
                  <IconWrapper>
                    <Icon
                      size="large"
                      name={catEligibilityStatement.panel_icon ?? ''}
                      color={colors.core01}
                      displayVariant
                    />
                  </IconWrapper>
                  <StyledPanelHeading>
                    {petNameReplacer(petDetails.petName, catEligibilityStatement.heading)}
                  </StyledPanelHeading>
                </StyledPanelHeadingContainer>
              </GridItem>
              <GridItem>
                <StyledRichTextWithModal
                  pageTitle={PageTitle.AboutYouAndYourPet}
                  html={petNameReplacer(
                    petDetails.petName,
                    catEligibilityStatement.statement_list
                  )}
                  customBulletIcon={catEligibilityStatement.customBulletIcon}
                />
              </GridItem>
              <GridItem>
                <StyledQuestionField
                  question={questions.catEligibilityQuestion}
                  errorText={getError('petDetails', ['petIsEligible', index])}
                  warningText={getWarning('petDetails', ['petIsEligible', index])}>
                  <BooleanRadioInput
                    buttonsPerRow={{ mobile: 1 }}
                    id={getIdForInput('petIsEligible')}
                    analyticsDescription={getAnalyticsDescriptionForInput(
                      'Agree with all statements about pet'
                    )}
                    value={petDetails.petIsEligible}
                    onChange={(value) => {
                      touchAssumption([
                        'not_involved_in_legal_action',
                        'no_complaints_about_behaviour',
                      ]);
                      updatePetDetails({
                        petIsEligible: value,
                      });
                      showValidation('petDetails', ['petIsEligible', index]);
                    }}
                  />
                </StyledQuestionField>
              </GridItem>
            </Grid>
          </StyledPanel>
        </FieldGrid>
      )}
      {((petIsEligible && !!petDetails.petName) ||
        (petIsDog && !!petDetails.petName && isGradulaRevealExperiment)) && (
        <>
          <StyledQuestionFieldWithMarginTop
            question={questions.petInGoodHealth}
            errorText={getError('petDetails', ['petInGoodHealth', index])}
            warningText={getWarning('petDetails', ['petInGoodHealth', index])}>
            <BooleanRadioInput
              id={getIdForInput('petInGoodHealth')}
              analyticsDescription={getAnalyticsDescriptionForInput(
                'Pet is in good health'
              )}
              value={petDetails.petInGoodHealth}
              onChange={(value) => {
                touchAssumption('good_health');
                updatePetDetails({
                  petInGoodHealth: value,
                });
                showValidation('petDetails', ['petInGoodHealth', index]);
              }}
            />
          </StyledQuestionFieldWithMarginTop>
        </>
      )}
      {showDogQuestions && (
        <DogBreedQuestions
          index={index}
          petDetails={petDetails}
          updatePetDetails={updatePetDetails}
          formValidation={{ getError, showValidation }}
          dogBreedReferenceData={dogBreedsRefData}
          pageTitle={PageTitle.AboutYouAndYourPet}
        />
      )}
      {showCatQuestions && (
        <CatBreedQuestions
          index={index}
          petDetails={petDetails}
          updatePetDetails={updatePetDetails}
          formValidation={{ getError, showValidation }}
          catBreedReferenceData={catBreedsRefData}
        />
      )}
      {(showDogQuestions || showCatQuestions) && (
        <>
          <QuestionField
            question={questions.petGender}
            errorText={getError('petDetails', ['petGender', index])}>
            <RadioInput
              id={getIdForInput('petGender')}
              value={petDetails.petGender}
              options={gendersRefData?.genders ?? []}
              onChange={(e) => {
                updatePetDetails({
                  petGender: e.target.value,
                });
                trackRadioButtonClick(
                  getAnalyticsDescriptionForInput('Pet gender'),
                  e.target.value
                );
              }}
            />
          </QuestionField>
          <QuestionField
            question={questions.petDob}
            errorText={getError('petDetails', ['petDob', index])}
            warningText={getWarning('petDetails', ['petDob', index])}
            initiallyShowTooltip>
            <StyledDateInput
              data-cy="pet-dob"
              id={getIdForInput('petDob')}
              value={petDetails.petDob}
              onChange={(value) => updatePetDetails({ petDob: value })}
              onBlur={() => {
                updatePetDetails({
                  petDob: addLeadingZerosToDateValue(petDetails.petDob),
                });
                updatePolicyDetails({
                  coverStartDate: updateCoverStartDate([petDetails]).toISOString(),
                });
                showValidation('petDetails', ['petDob', index]);
              }}
              onFocus={trackFormTextFieldFocus(
                getAnalyticsDescriptionForInput('Pet dob')
              )}
            />
          </QuestionField>
          <QuestionField
            question={questions.petCost}
            errorText={getError('petDetails', ['petCost', index])}>
            <CostInput
              id={getIdForInput('petCost')}
              value={petDetails.petCost}
              placeholder={questions.petCost.placeholder}
              onCostChange={(value) => {
                updatePetDetails({
                  petCost: value,
                });
              }}
              onBlur={() => showValidation('petDetails', ['petCost', index])}
              onFocus={trackFormTextFieldFocus(
                getAnalyticsDescriptionForInput('Pet cost')
              )}
              noCostButtonText={questions.petCost.noCostButtonText}
              onNoCostClicked={() =>
                trackRadioButtonClick(getAnalyticsDescriptionForInput('Pet cost'), '0')
              }
            />
          </QuestionField>
          <QuestionField
            question={questions.petSpayed}
            errorText={getError('petDetails', ['petSpayed', index])}>
            <BooleanRadioWithUnknownInput
              id={getIdForInput('petSpayed')}
              analyticsDescription={getAnalyticsDescriptionForInput('Is pet spayed')}
              value={petDetails.petSpayed}
              onChange={(value) => {
                updatePetDetails({
                  petSpayed: value,
                });
              }}
            />
          </QuestionField>
        </>
      )}
      {showCatQuestions && (
        <QuestionField
          question={questions.petChipped}
          errorText={getError('petDetails', ['petChipped', index])}>
          <BooleanRadioWithUnknownInput
            id={getIdForInput('petChipped')}
            analyticsDescription={getAnalyticsDescriptionForInput('Is pet chipped')}
            value={petDetails.petChipped}
            onChange={(value) => {
              updatePetDetails({
                petChipped: value,
              });
            }}
          />
        </QuestionField>
      )}
    </div>
  );
};

export default AboutYourPetForm;
