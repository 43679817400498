import { useEffect } from 'react';

// Hook to anchor page to given section from Id in the url when page loads
export const useAnchorToSection = (): void => {
  useEffect(() => {
    const hash = window.location.hash.slice(1); // Remove the '#' character from the hash
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
    }
  }, []);
};
